import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { HttpClient, HttpHeaders, HttpEvent, HttpErrorResponse, HttpEventType } from "@angular/common/http";
import { map } from "rxjs/operators";
import { User } from "../models/user";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /* login(userName: string, password: string) {
    return this.http
      .post<any>(`${environment.apiUrl}auth/login`, {
        userName,
        password,
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes

          localStorage.setItem("currentUser", JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  } */

  login(data) {
    return this.http
      .post<any>(`${environment.apiUrl}auth/login`, data)
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes

          localStorage.setItem("currentUser", JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  customerList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'customer/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  tenderReport(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'customer/tenderreport';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  candidateList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'user/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  get_by_mobileNo(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'user/get_by_mobileno';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  
  recruiterList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'hospital/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  recruiterList1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'hospital/authors1';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  userList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'auth/userlist';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  subscriptionList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'subscription/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addSubscription(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'subscription/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addUser(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'auth/signup';
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getSubscription(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'subscription/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getSociaLink(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'social/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getMainBanner(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'banner/main';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getSideSingle(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'banner/side';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getSideSlider(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'banner/side1';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getPostingNormal(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'banner/posting';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getPostingSlider(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'banner/posting1';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getApplicantSlider(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'banner/applicant';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getRecruiterSlider(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'banner/recruiter';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getFindjobSlider(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'banner/findjob';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateAlt(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'banner/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getFreelancerList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'freelancer/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getAboutus(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'aboutus/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getAdvertiseList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'myadvertise/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addAboutus(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'aboutus/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addAdvertise(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'myadvertise/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getTc(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'termcondition/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addTc(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'termcondition/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getSubTc(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'subterm/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addSubTc(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'subterm/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getSubVacancy(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'subvacancy/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addSubVacancy(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'subvacancy/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getFaqR(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'faqrecruiter/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addFaqR(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'faqrecruiter/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  invoiceList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'vacancypost/invoice';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  subList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'hospital/sublist';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  healthDay(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'healthcareday/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addHealthDay(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'healthcareday/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getHealthDay(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'healthcareday/author/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getAdvertise(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'myadvertise/author/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateHealthDay(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'healthcareday/author/'+id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateAdvertise(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'myadvertise/author/'+id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getFaqA(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'faqapplicant/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addSeo(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'seo/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getSeo(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'seo/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getComplaint(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'complaint/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getPunchline(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'punchline/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getApplicantlogin(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'applicantlogin/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getRecruiterlogin(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'recruiterlogin/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getInline(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'inline/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addFaqA(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'faqapplicant/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addComplaint(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'complaint/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addPunchline(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'punchline/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addApplicantlogin(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'applicantlogin/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addRecruiterlogin(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'recruiterlogin/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addInline(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'inline/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getPrivacy(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'privacystatement/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addPrivacy(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'privacystatement/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getAwaresafe(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'awaresafe/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addAwaresafe(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'awaresafe/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getSocialMedia(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'socialmedia/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addSocialMedia(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'socialmedia/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getWorkList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'work/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addWork(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'work/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getWork(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'work/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateWork(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'work/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getTestimonialList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'testimonial/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getStateList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'state/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getCityList(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'city/authors/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addRecruiter(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'hospital/author1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getCityList1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'city/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getSecuirtyfraud(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'secuirtyfraud/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addSecuirtyfraud(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'secuirtyfraud/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getContactus(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'contactus/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getProfessionList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'profession/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getProfession(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'profession/author/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addProfession(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'profession/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateProfession(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'profession/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteProfession(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'profession/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  activeCandidate(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'user/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  menuAllow(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'auth/menu-allow/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getMenu(userId, menuId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'auth/get-menu/' + userId + '/' + menuId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  myMenu(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'auth/my-menu/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  verificationMail(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'hospital/verificationmail/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteAdvertise(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'myadvertise/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getCategoryList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'category/authors1';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getCategoryList1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'category/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getCategory(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'category/author/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addCategory(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'category/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateCategory(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'category/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteCategory(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'category/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteWork(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'work/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getSpecialList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'specialization/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getSpecial(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'specialization/author/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addSpecial(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'specialization/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateSpecial(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'specialization/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getPostedbyList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'post/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getPostedby(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'post/author/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addPostedby(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'post/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updatePostedby(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'post/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getWorkTypeList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'time/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getAdvertisementList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'advertisement/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getApplicationsList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'application/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getWorkType(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'time/author/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getOverApplication(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'application/author/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getAdvertisement(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'advertisement/author/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addWorkType(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'time/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addAdvertisement(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'advertisement/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateWorkType(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'time/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateOverApplication(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'application/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addOverApplication(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'application/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateAdvertisement(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'advertisement/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getPayscalList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'salary/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getPayscal(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'salary/author/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addPayscal(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'salary/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updatePayscal(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'salary/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteSpecial(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'specialization/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteSub(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'subscription/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteRecruiter(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'hospital/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addContactus(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'contactus/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }


  addState(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'state/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateState(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'state/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteState(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'state/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getState(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'state/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }


  addCity(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'city/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateCity(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'city/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteCity(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'city/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getCity(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'city/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getCandidate(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'user/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getFreelancer(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'freelancer/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getRecruiter(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'hospital/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateRecruiter(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'hospital/update/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getRecruiterSubs(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'hospital/sublist';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  bannerUpload(id, data) {
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'banner/upload/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  advertisementUpload(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'advertisement/upload/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }

  logoUpload(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'hospital/upload2/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }

  subscriptionUpload(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'subscription/upload/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }

  excelImport(formData) {
    return this.http.post<any>(`${'https://localhost:7137/api/HealthCareDay'}`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }

  updateSubscription(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'subscription/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updatCandidate(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'user/author2/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updatFreelancer(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'freelancer/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  popularStatus(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'hospital/popular/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteHealthCareDay(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'healthcareday/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  popularList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'hospital/popularlist';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateSocialLink(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'social/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  reviewReport(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'tender/reviewreport';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  customerList1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'customer/authors1';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  installerList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'installer/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  blogList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'blog/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  projectList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'project/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  testimonialList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'testimonial/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  installerList1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'installer/authors1';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  tenderList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'tender/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  openTenderList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'tender/opentender';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  notOpenTenderList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'tender/notopentender';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteInstaller(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'installer/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteCandidate(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'user/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteFreelancer(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'freelancer/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  awardTender(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'application/award/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  closeTender(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'tender/close/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteInstaller1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'installer/author1/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  changepassword(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'auth/password/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  candidtateChangePassword(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'user/password/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteBlog(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'blog/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteProject(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'project/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deletePost(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'post/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteWorkType(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'time/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteApplication(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'application/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteAdvertisement(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'advertisement/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deletePayScale(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'salary/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteTestimonial(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'testimonial/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteCustomer(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'customer/author/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteCustomer1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'customer/author1/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getCustomer(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'customer/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getTestimonial(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'testimonial/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getTender(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'tender/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getTenderCustomer(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'tender/customer/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getBlog(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'blog/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getProject(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'project/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getInstaller(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'installer/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getPerformance(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'application/performance/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getApplicationList(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'application/application/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateCustomer(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'customer/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateApplication(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'application/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateTestimonial(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'testimonial/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateTender(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'tender/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  forgotPassword(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'auth/forgotpassword';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateBlog(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'blog/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateProject(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'project/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getUser(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'auth/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateInstaller(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'installer/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateUser(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'auth/user/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addCustomer(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'customer/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addBlog(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'blog/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addProject(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'project/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addTestimonial(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'testimonial/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addInstaller(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'installer/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addInstaller1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'installer/author1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addTender(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'tender/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addApplication(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'application/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  getApplication(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'application/data';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  private getEventMessage(event: HttpEvent<any>, formData) {

    switch (event.type) {
      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);
		break;
      case HttpEventType.Response:
        return this.apiResponse(event);
		break;
      default:
        return `File "${formData.get('profile').name}" surprising upload event: ${event.type}.`;
    }
  }

  private fileUploadProgress(event) {
    const percentDone = Math.round(100 * event.loaded / event.total);
    return { status: 'progress', message: percentDone };
  }

  private apiResponse(event) {
    return event.body;
  }

  blogUpload(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'blog/upload/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }

  profileUpload(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'installer/upload/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }

  profileUpload1(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'auth/upload/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }

  projectUpload(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'project/upload/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }

  testimonialUpload(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'testimonial/upload/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }

  addMyBlog(data): Observable<any>{
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          
        }
      ), 
    };
    let URL = environment.apiUrl + 'blog/create';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  
  allBlog(): Observable<any>{
    
    let URL = environment.apiUrl + 'blog/all';
    return this.http.get<any>(URL).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteMyBlog(id): Observable<any>{
    
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          
        }
      ), 
    };
    let URL = environment.apiUrl + 'blog/delete/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }


  singleBlog(id:any): Observable<any>{
    
    let URL = environment.apiUrl + 'blog/'+id;
    return this.http.get<any>(URL).
    pipe(
      map(res =>{
        return res;
      })
    )
  }


  updateMyBlog(id:any, data:any ): Observable<any>{
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          
        }
      ), 
    };
    let URL = environment.apiUrl + 'blog/update/'+id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }



  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
    return of({ success: false });
  }
}
