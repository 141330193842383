import { RouteInfo } from "./sidebar.metadata";
export const ROUTES: RouteInfo[] = [
  {
    path: "/admin/dashboard/main",
    title: "Dashboard",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["SuperAdministrator"],
    submenu: [],
  },
  {
    path: "",
    title: "User Access",
    moduleName: "user",
    iconType: "material-icons-two-tone",
    icon: "person",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["SuperAdministrator"],
    submenu: [
      {
        path: "/user/userlist",
        title: "User List",
        moduleName: "user",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      }
    ],
  }, 
  {
    path: "",
    title: "Settings",
    moduleName: "settings",
    iconType: "material-icons-two-tone",
    icon: "settings",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["SuperAdministrator"],
    submenu: [
      {
        path: "/settings/banner",
        title: "Banner",
        moduleName: "settings",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/candidate/plan",
        title: "System Backup",
        moduleName: "settings",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/settings/healthdays",
        title: "Health Days",
        moduleName: "settings",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/settings/sociallink",
        title: "Social Media Link Master",
        moduleName: "settings",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/settings/subscription",
        title: "Subscriptions",
        moduleName: "settings",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/settings/profession",
        title: "Category",
        moduleName: "settings",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      /* {
        path: "/settings/specialization",
        title: "Specialization",
        moduleName: "settings",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      }, */
      /* {
        path: "/settings/postedby",
        title: "Posted By",
        moduleName: "settings",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      }, */
      {
        path: "/settings/worktype",
        title: "Work Type",
        moduleName: "settings",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/settings/payscale",
        title: "Salary Type",
        moduleName: "settings",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/settings/location",
        title: "Location",
        moduleName: "settings",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      }
    ],
  },
  {
    path: "",
    title: "CMS",
    moduleName: "cms",
    iconType: "material-icons-two-tone",
    icon: "diversity_2",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["SuperAdministrator"],
    submenu: [
      {
        path: "/cms/aboutus",
        title: "About Us",
        moduleName: "cms",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/cms/blog",
        title: "Blog",
        moduleName: "cms",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/cms/termcondition",
        title: "Terms & Conditions",
        moduleName: "cms",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/cms/contact",
        title: "Contact",
        moduleName: "cms",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      /* {
        path: "/cms/advisory",
        title: "Advisory Panel",
        moduleName: "cms",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      }, */
      {
        path: "/cms/applicantlogin",
        title: "Login Page - Punch Lines",
        moduleName: "cms",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/cms/advertise",
        title: "Advertise With Us",
        moduleName: "cms",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/cms/privacystatement",
        title: "Privacy Statement",
        moduleName: "cms",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/cms/awaresafe",
        title: "Be Aware Be Safe",
        moduleName: "cms",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/cms/faqr",
        title: "FAQ for Recruiters",
        moduleName: "cms",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/cms/faqa",
        title: "FAQ for Applicants",
        moduleName: "cms",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/cms/socialmedia",
        title: "Social Media Policy",
        moduleName: "cms",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/cms/secuirtyfraud",
        title: "Security & Frauds",
        moduleName: "cms",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/cms/refund",
        title: "Refund & Cancellation",
        moduleName: "cms",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/cms/work",
        title: "Work With Us",
        moduleName: "cms",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/cms/complaint",
        title: "Complaints",
        moduleName: "cms",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/cms/seo",
        title: "SEO",
        moduleName: "cms",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/cms/punchline",
        title: "Front Page - Punch Lines",
        moduleName: "cms",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/cms/testimonial",
        title: "Testimonials",
        moduleName: "cms",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      }
    ],
  },
  {
    path: "",
    title: "Applicant Database",
    moduleName: "applicant",
    iconType: "material-icons-two-tone",
    icon: "school",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["SuperAdministrator"],
    submenu: [
      {
        path: "/candidate/registration",
        title: "Registration",
        moduleName: "applicant",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      /* {
        path: "/candidate/contract",
        title: "Contract",
        moduleName: "applicant",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/candidate/plan",
        title: "Plan",
        moduleName: "applicant",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      }, */
    ],
  },
  {
    path: "",
    title: "Recruiter Database",
    moduleName: "recruiter",
    iconType: "material-icons-two-tone",
    icon: "manage_accounts",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["SuperAdministrator"],
    submenu: [
      {
        path: "/recruiter/registration",
        title: "Registration",
        moduleName: "recruiter",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      /* {
        path: "/recruiter/contract",
        title: "Contract",
        moduleName: "recruiter",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/recruiter/plan",
        title: "Plan",
        moduleName: "recruiter",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      }, */
    ],
  },
  {
    path: "",
    title: "Freelancer Database",
    moduleName: "freelancer",
    iconType: "material-icons-two-tone",
    icon: "manage_accounts",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["SuperAdministrator"],
    submenu: [
      {
        path: "/freelancer/registration",
        title: "Registration",
        moduleName: "freelancer",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      /* {
        path: "/recruiter/contract",
        title: "Contract",
        moduleName: "recruiter",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/recruiter/plan",
        title: "Plan",
        moduleName: "recruiter",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      }, */
    ],
  },
  {
    path: "/cms/invoicesummary",
    title: "Invoice Summary",
    moduleName: "invoice",
    iconType: "material-icons-two-tone",
    icon: "receipt",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["SuperAdministrator"],
    submenu: [],
  },
  {
    path: "/oversease/advertisement",
    title: "Oversease Healthcare Jobs",
    moduleName: "oversease",
    iconType: "material-icons-two-tone",
    icon: "newspaper",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["SuperAdministrator"],
    submenu: [],
  },
  {
    path: "/oversease/application",
    title: "Oversease Healthcare App",
    moduleName: "oversease",
    iconType: "material-icons-two-tone",
    icon: "tab",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["SuperAdministrator"],
    submenu: [],
  },


  //BRANCH USER
  
];
