import { Router, NavigationEnd } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy,
} from "@angular/core";
import { ROUTES } from "./sidebar-items";
import { AuthService } from "src/app/core/service/auth.service";
import { Role } from "src/app/core/models/role";
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public sidebarItems: any[];
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  userFullName: string;
  userImg: string;
  userType: string;
  headerHeight = 60;
  currentRoute: string;
  routerObj = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private router: Router
  ) {
    const body = this.elementRef.nativeElement.closest("body");
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, "overlay-open");
      }
    });
  }
  @HostListener("window:resize", ["$event"])
  windowResizecall(event) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }
  @HostListener("document:mousedown", ["$event"])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, "overlay-open");
    }
  }

  callToggleMenu(event: any, length: any) {
    if (length > 0) {
      const parentElement = event.target.closest("li");
      const activeClass = parentElement.classList.contains("active");

      if (activeClass) {
        this.renderer.removeClass(parentElement, "active");
      } else {
        this.renderer.addClass(parentElement, "active");
      }
    }
  }
  ngOnInit() {
    if (this.authService.currentUserValue) {
      const userRole = localStorage.getItem("USERTYPE");
      this.userType = userRole;
      this.userFullName = localStorage.getItem("USERNAME");
      this.userImg = this.authService.currentUserValue.img;

      if(userRole=="SuperAdministrator") {
        this.sidebarItems = ROUTES.filter(
          (x) => x.role.indexOf(userRole) !== -1 || x.role.indexOf("All") !== -1
        );
      }
      else {
        this.sidebarItems = [
          {
            path: "/admin/dashboard/main",
            title: "Dashboard",
            moduleName: "dashboard",
            iconType: "material-icons-two-tone",
            icon: "dashboard",
            class: "",
            groupTitle: false,
            badge: "",
            badgeClass: "badge bg-blue sidebar-badge float-end",
            role: ["Branch User"],
            submenu: [],
          },
          {
            path: "",
            title: "Settings",
            moduleName: "settings",
            iconType: "material-icons-two-tone",
            icon: "settings",
            class: "menu-toggle",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: ["Branch User"],
            submenu: [
              {
                path: "/settings/banner",
                title: "Banner",
                moduleName: "settings",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/candidate/plan",
                title: "System Backup",
                moduleName: "settings",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/settings/healthdays",
                title: "Health Days",
                moduleName: "settings",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/settings/sociallink",
                title: "Social Media Link Master",
                moduleName: "settings",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/settings/subscription",
                title: "Subscriptions",
                moduleName: "settings",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/settings/profession",
                title: "Category",
                moduleName: "settings",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              /* {
                path: "/settings/specialization",
                title: "Specialization",
                moduleName: "settings",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              }, */
              /* {
                path: "/settings/postedby",
                title: "Posted By",
                moduleName: "settings",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              }, */
              {
                path: "/settings/worktype",
                title: "Work Type",
                moduleName: "settings",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/settings/payscale",
                title: "Salary Type",
                moduleName: "settings",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/settings/location",
                title: "Location",
                moduleName: "settings",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "CMS",
            moduleName: "cms",
            iconType: "material-icons-two-tone",
            icon: "diversity_2",
            class: "menu-toggle",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: ["Branch User"],
            submenu: [
              {
                path: "/cms/aboutus",
                title: "About Us",
                moduleName: "cms",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/cms/blog",
                title: "Blog",
                moduleName: "cms",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/cms/termcondition",
                title: "Terms & Conditions",
                moduleName: "cms",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/cms/contact",
                title: "Contact",
                moduleName: "cms",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              /* {
                path: "/cms/advisory",
                title: "Advisory Panel",
                moduleName: "cms",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              }, */
              {
                path: "/cms/applicantlogin",
                title: "Login Page - Punch Lines",
                moduleName: "cms",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/cms/advertise",
                title: "Advertise With Us",
                moduleName: "cms",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/cms/privacystatement",
                title: "Privacy Statement",
                moduleName: "cms",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/cms/awaresafe",
                title: "Be Aware Be Safe",
                moduleName: "cms",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/cms/faqr",
                title: "FAQ for Recruiters",
                moduleName: "cms",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/cms/faqa",
                title: "FAQ for Applicants",
                moduleName: "cms",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/cms/socialmedia",
                title: "Social Media Policy",
                moduleName: "cms",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/cms/secuirtyfraud",
                title: "Security & Frauds",
                moduleName: "cms",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/cms/refund",
                title: "Refund & Cancellation",
                moduleName: "cms",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/cms/work",
                title: "Work With Us",
                moduleName: "cms",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/cms/complaint",
                title: "Complaints",
                moduleName: "cms",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/cms/seo",
                title: "SEO",
                moduleName: "cms",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/cms/punchline",
                title: "Front Page - Punch Lines",
                moduleName: "cms",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/cms/testimonial",
                title: "Testimonials",
                moduleName: "cms",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              }
            ],
          },
          {
            path: "",
            title: "Applicant Database",
            moduleName: "applicant",
            iconType: "material-icons-two-tone",
            icon: "school",
            class: "menu-toggle",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: ["Branch User"],
            submenu: [
              {
                path: "/candidate/registration",
                title: "Registration",
                moduleName: "applicant",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              /* {
                path: "/candidate/contract",
                title: "Contract",
                moduleName: "applicant",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/candidate/plan",
                title: "Plan",
                moduleName: "applicant",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              }, */
            ],
          },
          {
            path: "",
            title: "Recruiter Database",
            moduleName: "recruiter",
            iconType: "material-icons-two-tone",
            icon: "manage_accounts",
            class: "menu-toggle",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: ["Branch User"],
            submenu: [
              {
                path: "/recruiter/registration",
                title: "Registration",
                moduleName: "recruiter",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              /* {
                path: "/recruiter/contract",
                title: "Contract",
                moduleName: "recruiter",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/recruiter/plan",
                title: "Plan",
                moduleName: "recruiter",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              }, */
            ],
          },
          {
            path: "",
            title: "Freelancer Database",
            moduleName: "freelancer",
            iconType: "material-icons-two-tone",
            icon: "manage_accounts",
            class: "menu-toggle",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: ["Branch User"],
            submenu: [
              {
                path: "/freelancer/registration",
                title: "Registration",
                moduleName: "freelancer",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              /* {
                path: "/recruiter/contract",
                title: "Contract",
                moduleName: "recruiter",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
              {
                path: "/recruiter/plan",
                title: "Plan",
                moduleName: "recruiter",
                iconType: "",
                icon: "",
                class: "ml-menu",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              }, */
            ],
          },
          {
            path: "/cms/invoicesummary",
            title: "Invoice Summary",
            moduleName: "invoice",
            iconType: "material-icons-two-tone",
            icon: "receipt",
            class: "",
            groupTitle: false,
            badge: "",
            badgeClass: "badge bg-blue sidebar-badge float-end",
            role: ["Branch User"],
            submenu: [],
          },
          {
            path: "/oversease/advertisement",
            title: "Oversease Healthcare Jobs",
            moduleName: "oversease",
            iconType: "material-icons-two-tone",
            icon: "newspaper",
            class: "",
            groupTitle: false,
            badge: "",
            badgeClass: "badge bg-blue sidebar-badge float-end",
            role: ["Branch User"],
            submenu: [],
          },
          {
            path: "/oversease/application",
            title: "Oversease Healthcare App",
            moduleName: "oversease",
            iconType: "material-icons-two-tone",
            icon: "tab",
            class: "",
            groupTitle: false,
            badge: "",
            badgeClass: "badge bg-blue sidebar-badge float-end",
            role: ["Branch User"],
            submenu: [],
          }
        ]
      }
      
      /* if (userRole === Role.Admin) {
        this.userType = Role.Admin;
      } else if (userRole === Role.Patient) {
        this.userType = Role.Patient;
      } else if (userRole === Role.Doctor) {
        this.userType = Role.Doctor;
      } else {
        this.userType = Role.Admin;
      } */
    }

    // this.sidebarItems = ROUTES.filter((sidebarItem) => sidebarItem);
    this.initLeftSidebar();
    this.bodyTag = this.document.body;
  }
  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }
  initLeftSidebar() {
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }
  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + "";
    this.listMaxWidth = "500px";
  }
  isOpen() {
    return this.bodyTag.classList.contains("overlay-open");
  }
  checkStatuForResize(firstTime) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, "ls-closed");
    } else {
      this.renderer.removeClass(this.document.body, "ls-closed");
    }
  }
  mouseHover(e) {
    const body = this.elementRef.nativeElement.closest("body");
    if (body.classList.contains("submenu-closed")) {
      this.renderer.addClass(this.document.body, "side-closed-hover");
      this.renderer.removeClass(this.document.body, "submenu-closed");
    }
  }
  mouseOut(e) {
    const body = this.elementRef.nativeElement.closest("body");
    if (body.classList.contains("side-closed-hover")) {
      this.renderer.removeClass(this.document.body, "side-closed-hover");
      this.renderer.addClass(this.document.body, "submenu-closed");
    }
  }
  logout() {
    this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(["/authentication/signin"]);
      }
    });
  }
}
